import React, { useEffect, useState } from "react";

export const ApiDataContext = React.createContext({});

function ApiDataProvider({ children, url }) {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const categories = ["Spirekor", "Cross Yoga", "Konfirmand", "Seniorsang", "Babysalmesang 1", "Bongo 2", "Bongo 4", "Bongo 5", "Strikkecafe", "Koncert"];
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                     setData(result.filter(obj => {
                        let value = 0;
                        categories.map(category => {
                            if(obj.Labels.includes(category)) {
                                value = 1;
                            }
                            return null;
                         });
                         return (value === 1);
                     }));
                },
                (error) => {
                    setError(error);
                }
            )
        }, [url])
        
    return (
        <ApiDataContext.Provider value={{ data, error }}>
            {children}
        </ApiDataContext.Provider>
    )
}

export default ApiDataProvider;